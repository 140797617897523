import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="edit-clinician"
export default class extends Controller {
  connect() {
    console.log('edit Clinician')
  }

  handleChange(e){
    if (e.target.value === 'fax') {
      $('#phone_number_update').parent().show()
    } else {
      $('#phone_number_update').parent().hide()
    }
  }

  formValidation(e) {
    if ( $('#clinician_kit_method').val() === 'fax' && $('#phone_number_update').val() === '' ){
      e.preventDefault()
      alert('Please input a number for Faxing')
    }   
  }
}

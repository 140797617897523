import { Controller } from "@hotwired/stimulus"
import { Calendar } from "@fullcalendar/core";
import { useIntersection } from 'stimulus-use'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

// Connects to data-controller="davids-cal"
export default class extends Controller {
  static targets = ['calendar', 'start', 'end']

  initialize() {
    this.state = {
      slot: null  
    }
  }

  connect() {
    useIntersection(this)
  }

  appear() {
    const state = this.state
    let calElement = this.calendarTarget

    let calendar = new Calendar(calElement, {
      events: "/davids_events.json",
      eventClick: function(info) {
        let selectedColor = '#bfed6e'
        let defaultColor = '#3a87ad'

        if (state.slot === null ){
          state.slot = info.el
          info.el.style.borderColor = selectedColor;
          info.el.style.backgroundColor = selectedColor;
          
        } else if (state.slot != null & state.slot != info.el) {
          state.slot.style.borderColor = defaultColor;
          state.slot.style.backgroundColor = defaultColor;

          info.el.style.borderColor = selectedColor;
          info.el.style.backgroundColor = selectedColor;
          state.slot = info.el;
        }

        this.updateForm(info.event.startStr, info.event.endStr)
      }.bind(this),
      initialView: "timeGridWeek",
      slotMinTime: "10:00:00",
      slotMaxTime: "16:00:00",
      height: 'auto',
      weekends: false,
      editable: false,
      navLinks: true,
      headerToolbar: { center: "dayGridMonth,timeGridWeek,timeGridDay" },
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    });
    calendar.render();
  }

  updateForm(start, end) {
    this.startTarget.value = start
    this.endTarget.value = end
  }
}

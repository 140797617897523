import { Controller } from "@hotwired/stimulus"
// import { TinyMCE } from "tinymce"
import tinymce from 'tinymce/tinymce'
import 'tinymce/models/dom/model'

import 'tinymce/icons/default/icons'
import 'tinymce/themes/silver/theme';
import 'tinymce/skins/ui/oxide/skin.min.css';

// Connects to data-controller="tinymce-frame"
export default class extends Controller {
  static targets = ['tinyInput']

  initialize() {
    this.default = {
      content_css: false,
      skin: false,
      height: 400,
      browser_spellcheck: true,
      link_default_target: '_blank',
      menubar: false,
      branding: false,
      plugins: ['advlist', 'autolink', 'lists', 'charmap', 'print', 'paste', 'autosave'],
      toolbar: 'undo redo | fontselect | fontsizeselect | ' +
        'bold italic forecolor backcolor | alignleft aligncenter ' +
        'alignright alignjustify | formatselect | lineheight bullist numlist outdent indent | ' +
        'removeformat | help',
      content_style: 'body { line-height: 1; } p { margin: 0; }',
    }

    let config = Object.assign({ target: this.tinyInputTarget }, this.defaults)
    tinymce.init(config)
  }

  connect() {
    console.log('Tyson was here :D')
    tinymce.remove()
    tinymce.init({
      selector: '.tinymce',
      height: 400,
      browser_spellcheck: true,
      link_default_target: '_blank',
      menubar: false,
      branding: false,
      plugins: ['advlist', 'autolink', 'lists', 'charmap', 'print', 'paste', 'autosave'],
      toolbar: 'undo redo | fontselect | fontsizeselect | ' +
        'bold italic forecolor backcolor | alignleft aligncenter ' +
        'alignright alignjustify | formatselect | lineheight bullist numlist outdent indent | ' +
        'removeformat | help',
      content_style: 'body { line-height: 1; } p { margin: 0; }',
    });


    // console.log(this.tinyInputTarget)
    // let config = Object.assign({ target: this.tinyInputTarget }, this.defaults)
    // tinymce.init(config)
  }

  disconnect () {
    tinymce.remove()
  }
}

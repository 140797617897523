import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="insurance-box"
export default class extends Controller {
  static targets = ['insurance', 'search', 'insuranceList']

  connect() {
    console.log('Insurance Box is loaded')
    let form = document.getElementById('new_insurance')
    form.setAttribute('data-action', 'insurance-box#newInsuranceForm')
  }

  search() {
    if (typeof this.original === 'undefined'){
      this.original = this.insuranceTargets
    }

    let insurances = this.original
    let userInput = this.searchTarget.value

    this._showTyson(userInput)

    let filterd = insurances.filter(i => i.innerText.includes(userInput) || i.innerText.toLowerCase().includes(userInput) || i.innerText.toUpperCase().includes(userInput))
    this.insuranceListTarget.replaceChildren(...filterd)
  }

  deleteInsurance(e) {
    let url = window.location.href + '/' + e.target.parentElement.innerText
    fetch(url, {
      method: "DELETE", // *GET, POST, PUT, DELETE, etc.
    })
      .then(() => {
        console.log('delete')
        e.target.parentElement.remove()
      })
  }

  _showTyson(input) {
    let tyson = document.getElementById('tyson-worried')

    if (input === 'the seahawks suck') {
      tyson.classList.add('show-tyson')
    } 
  }

  // newInsuranceForm(e){
  //   console.log(e)
  //   // document.getElementById('insurances_frame').src = window.location.origin + '/ronnys_corner/insurances_frame'
  //   // $('#new-insurance-modal').hide()
  // }
}

import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["output"]

  connect() {
    // Log a message when the controller connects
    console.log("Hello controller connected!");

    if (this.hasOutputTarget) {
      this.outputTarget.textContent = "The controller is connected and the target is found?????";
      console.log("Output target found and updated!!!!");
    } else {
      console.error("Missing target element 'output' for 'hello' controller");
    }
  }
}
import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="contact-form"
export default class extends Controller {
  
  initialize() {
    this.State = {
      primary: false,
      secondary: false
    }
  }

  connect() {
    $('#contact_responsibilities_primary_full_crc_access').data('action', 'contact-form#updateForm')
    $('#contact_responsibilities_secondary_full_crc_access').data('action', 'contact-form#updateForm')
  }

  updateForm(e){
    this._updateState(e)
    this.markAll()
  }

  markAll(){
    if (this.State.primary || this.State.secondary) {
      let communication_options = document.getElementsByClassName('communication_options')[0].getElementsByTagName('span')
      let responsibilities = document.getElementsByClassName('responsibilities')[0].getElementsByTagName('span')
      // Combines the HTMLCollections
      let spans = []
      spans = Array.prototype.concat.apply(spans, responsibilities)
      spans = Array.prototype.concat.apply(spans, communication_options)
      // checks them all
      for (let el of spans) {
        let input = el.getElementsByTagName('input')[0]
        if (input.id != 'contact_responsibilities_primary_full_crc_access' & input.id != 'contact_responsibilities_secondary_full_crc_access') {
          input.checked = true
        }
      }
    }
  }

  _updateState(e){
    let inputId = e.target.id.split('contact_responsibilities_')[1]

    if (inputId == 'primary_full_crc_access') {
      this.State.primary = !this.State.primary
    } else if (inputId == 'secondary_full_crc_access') {
      this.State.secondary = !this.State.secondary
    }
  }
}

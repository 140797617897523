import { Controller } from "@hotwired/stimulus"
import confetti  from 'canvas-confetti'
import { StreamActions } from "@hotwired/turbo"

// Connects to data-controller="task"
export default class extends Controller {
  connect() {
    StreamActions.closeTaskModal = this.handleSubmit
  }
  complete(e) {
    this._throwConfetti(this._getXY(e.target))
    this._sendRequest(e);
  }

  _transform(e) {
    const i = document.createElement('i');
    i.className = 'fa fa-check';
    e.target.replaceWith(i);
  }

  _getXY(el) {
    let width = window.innerWidth;
    let height = window.innerHeight
    let rect = el.getBoundingClientRect();

    let originX = (rect.x + (0.5 * rect.width)) / width;
    let originY = (rect.y + (0.5 * rect.height)) / height;

    return {x: originX, y: originY}
  }

  _throwConfetti(object) {
    // docs: -> https://www.kirilv.com/canvas-confetti/
    confetti({
      particleCount: 100,
      spread: 70,
      origin: object
    });
  }
  _sendRequest(e) {
    let url = `${location.origin}${e.target.dataset.url}`;
    fetch(url)
      .then(
        this._transform(e)
      )
  }
  
  handleSubmit() {
    $(`#edit_task_${this.getAttribute('task')}`).modal('hide');
  }
}

import { Controller } from "stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useIntersection } from 'stimulus-use'
import waitForElm from "../helpers/waitForElement";

export default class extends Controller {
  static targets = ["cccalendar"];
  static values = {
    client: Object
  }

  connect() {
    console.log("CCCalendarController connected");
    useIntersection(this)
  }

  appear() {
    const client = this.clientValue
    let cal = this.cccalendarTarget
    
    let calendar = new Calendar(this.cccalendarTarget, {
      events: "/calendar/cc_session.json",
      loading: function(isloading) {
        if (isloading) {
          console.log('still loading')
        }
        else {            
          let gif = document.getElementById('ccalendar-loader')
          if (gif !== null) {gif.remove()}
          this.render()
        }
      },
      eventClick: function(info) {
        if ($.isEmptyObject(client)) {
          info.jsEvent.preventDefault();
        } else {
          info.jsEvent.preventDefault(); // don't let the browser navigate
          if (info.event.url) {
            let url = info.event.url
            SavvyCal('open', { // opening savvycal portal 
              link: `${url.split('/')[3]}/${url.split('/')[4]}`,
              metadata: {
                client_id: client.id,
                intake_id: window.location.href.split('/')[4] //saving intake id
              },
              email: client.email,
              displayName: client.displayName
            });
            waitForElm('#sc-embed > div > div').then((element) => {
              element.css('z-index', 10000000)
            })
          }
        }
      },
      initialView: "timeGridWeek",
      slotMinTime: "07:00:00",
      slotMaxTime: "22:00:00",
      height: 'auto',
      weekends: true,
      editable: false,
      navLinks: true,
      headerToolbar: { center: "dayGridMonth,timeGridWeek,timeGridDay" },
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
    });
  }
}

import { Controller } from "@hotwired/stimulus"
import tinymce from 'tinymce/tinymce'
import 'tinymce/icons/default';

/* A theme is also required */
import 'tinymce/themes/silver';

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/autosave';

import contentCss from 'tinymce/skins/content/default/content.css';

// returns a unique ID that will increase by 1 every time it's called
let currentId = 0
const getTinyId = () => {
  currentId += 1
  return currentId
}

// sets the global value of current editor ID to avoid reloading existing editors
let currentEditorId
const setCurrentEditorId = (id) => {
  currentEditorId = id
}

// Connects to data-controller="tinymce"
// Apply data controller anywhere that loads the textarea you wish to transform
export default class extends Controller {
  connect() {
    this.unobservedElements().forEach(element => {
      const observer = new IntersectionObserver(this.initializeTiny)
      observer.observe(element)
      element.dataset.tinymceobserver = true
      element.dataset.tinymceobserverid = getTinyId()
    })
  }

  // provides a list of tinymce textarea's that do not have an observer applied to them
  unobservedElements() {
    return Array.from(document.getElementsByClassName('tinymce')).filter((element) => {
      return !element.dataset.tinymceobserver
    })
  }

  // initialize function for tiny that will run when element is in view
  // Handles overwriting existing editors with the same name as well as preventing loading the same editor twice
  initializeTiny = (entries, observer) => {
    entries.forEach(e => {
      if (e.intersectionRatio > 0) {
        const element = e.target
        if (currentEditorId != element.dataset.tinymceobserverid) {
          if (tinymce.get(element.id)) {
            tinymce.get(element.id).remove()
          }
          setCurrentEditorId(element.dataset.tinymceobserverid)
          tinymce.init(this.tinymceOptions(element))
          this.removeRequiredProp(element)
        }
      }
    })
  }

  uniqueElementSelector = (element) => {
    return `[data-tinymceobserverid="${element.dataset.tinymceobserverid}"]`
  }

  removeRequiredProp = (element) => {
    $(this.uniqueElementSelector(element)).prop('required', false)
  }

  // Returns the object passed to tinymce.init and allows for different editor options
  tinymceOptions(element) {
    const options = {
      default: {
        selector: this.uniqueElementSelector(element),
        height: 400,
        browser_spellcheck: true,
        link_default_target: '_blank',
        menubar: false,
        branding: false,
        plugins: ['advlist', 'autolink', 'lists', 'charmap', 'autosave'],
        toolbar: 'undo redo | blocks fontfamily | fontsize | ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright alignjustify | formatselect | lineheight bullist numlist outdent indent | ' +
            'removeformat | help',
        skin: false,
        content_css: false,
        newline_behavior : 'linebreak',
        content_style: 'body { font-family: "Open Sans", sans-serif; }'
      },
      intakeEdit: {
        selector: this.uniqueElementSelector(element),
        height: 200,
        browser_spellcheck: true,
        link_default_target: '_blank',
        menubar: false,
        branding: false,
        plugins: ['advlist', 'autolink', 'lists', 'charmap', 'autosave'],
        toolbar: 'undo redo | bold underline italic forecolor',
        color_map: ['236fa1', 'Blue'],
        custom_colors: false,
        skin: false,
        content_css: false,
        newline_behavior : 'linebreak',
        content_style: 'body { font-family: "Open Sans", sans-serif; }'
      }
    }

    return options[element.dataset.preset || 'default']
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.constructTaskModal()
    $(`#${this.element.id}`).modal().show()
  }

  disconnect() {
    $(`#${this.element.id}`).modal().hide()
  }

  constructTaskModal() {
    $('button.task-submit-button').click(function (e) {
      var $assigned_to = $('#task_assigned_to');
      if (!$('#task_due_date').val().length && $assigned_to.val() != '0') {
        e.preventDefault();
        window.alert('Please add a due date');
      }
    });

    $('.date-picker').datepicker()
  }
}

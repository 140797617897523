import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="screenings"
export default class extends Controller {
  connect() {
    $('#alcohol_screen').modal('hide')
    $('#drug_screen').modal('hide')

    if (this.element.dataset.screeningType) {
      $.createIntakeNotification(`${this.element.dataset.screeningType} screening successfully completed.`)
    }
    if (this.element.dataset.screeningErrors) {
      if (this.element.dataset.preliminaryScreening) {
        $.createIntakeNotification(`Preliminary Alcohol Screening Saved.`)
      } else {
        $.createIntakeNotification(`Screening failed to create: ${this.element.dataset.screeningErrors}`, 'danger')
      }
    }
  }

  updateAlcoholScreening() {
    $('#screening_intro_weekly_average').val($('#screening_intro_how_often').val() * $('#screening_intro_how_much').val()).change()

    if ($('#client_gender').val() == 'Male') {
      if ($('#screening_intro_weekly_average').val() > 14) {
        this.toggleScreeningOn()
      } else {
        this.toggleScreeningOff()
      }
    }
    if ($('#client_gender').val() == 'Female') {
      if ($('#screening_intro_weekly_average').val() > 7) {
        this.toggleScreeningOn()
      } else {
        this.toggleScreeningOff()
      }
    }
    if (!['Female', 'Male'].includes($('#client_gender').val()) ) {
      if ($('#screening_intro_max').val() > 5) {
        this.toggleScreeningOn()
      } else {
        this.toggleScreeningOff()
      }
    }
  }

  toggleScreeningOn() {
    $('#screening_intro_weekly_average').closest('div').addClass('has-error');
    $('#alcohol_screening_form').removeClass('hidden')
    $('.alcohol-screening-modal').removeClass('modal-xs')
    $('.alcohol-screening-modal').addClass('modal-full')
    $('#screening_full_screening_necessary_needed').val(true)
  }
  
  toggleScreeningOff() {
    $('#screening_intro_weekly_average').closest('div').removeClass('has-error');
    $('#alcohol_screening_form').addClass('hidden')
    $('.alcohol-screening-modal').removeClass('modal-full')
    $('.alcohol-screening-modal').addClass('modal-xs')
    $('#screening_full_screening_necessary_needed').val('')
  }
}

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"

// Connects to data-controller="interventions"
export default class extends Controller {
  connect() {
    if (this.element.dataset.source === 'frame') {
      this.interventionFrameLogic()
    }
  }

  changeIssue(e) {
    this.url = "/intakes/interventions?" + new URLSearchParams({issues: $("#issues").val(), intake_id: window.location.pathname.replace('/intakes/', '')})
    fetch(this.url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  interventionFrameLogic() {
    $('.worklife-vendor-modal').on('click', (e) => {
      $('#assign_worklife_vendor').modal('show')
      $('.worklife_vendor_id_field').val(parseInt(e.target.name))
      $('.worklife_vendor_id_field').trigger("change")
    })

    $('.direct-assign-2').on('click', (e) => {
      if (!confirm(`Are you sure you want to assign ${e.target.innerText}`)) {
        e.preventDefault()
        return
      }
      
      if (e.target.name === 'affiliate') {
        $('#intake_clinician_id').val(e.target.dataset.vendorId)
      } else {
        $('#intake_worklife_vendor_id').val(e.target.dataset.vendorId)
      }
    })

    $('a[href*="generic_worklife_task"]').on('click', function (e) {
      var taskName = e.target.name
      $('#generic_worklife_task_title').text(taskName)
      $('#generic_worklife_task_form input[name="task[title]"]').val(taskName)
      $('#generic_worklife_task_form input[name="task[task_type]"]').val(taskName)
    })

    if (!$('#worklife_suggestions').children().length) {
      $('#worklife_suggestions_title').css('display', 'none')
    }

    if (!$('#worklife_search_suggestions').children().length) {
      $('#worklife_search_suggestions_title').css('display', 'none')
    }

    if (!$('#clinical_suggestions').children().length) {
      $('#clinical_suggestions_title').css('display', 'none')
    }

    $('.assign_david_intervention').on('click', (e) => {
      $('#request_worklife_service #service_approval_override_vendorable_id').val(e.target.name)
      $('#request_worklife_service').modal('show')
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import { Turbo } from "@hotwired/turbo-rails"
import { StreamActions } from "@hotwired/turbo"

// Connects to data-controller="worklife-vendor"
export default class extends Controller {
  connect() {
    if(this.element.dataset.scheduling) {
      $('.worklife_vendor_date_and_time_div').show();
    } else {
      $('.worklife_vendor_date_and_time_div').hide();
    }

    $('.worklife_vendor_id_field').on('change', (e) => {
      if (e.target.value === '') {
        $('#assign_worklife_vendor_info').empty()
      } else {
        this.setInfo(e.target.value)
      }
    })
    StreamActions.assignWorklifeVendor = this.handleSubmit
  }

  setInfo(id) {
    fetch(`/worklife_vendors/${id}/info`, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }

  handleSubmit() {
    $('#assign_worklife_vendor').modal('hide')

    if ($('#edit_intake').hasClass('in')) {
      $('#all_worklife_interventions').modal('hide')
      if (this.getAttribute('errors')) {
        $.createIntakeNotification(`Worklife Vendor Failed To Assign: ${this.getAttribute('errors')}`, 'danger')
      } else {
        $.createIntakeNotification('Worklife Vendor Successfully Assigned')
      }
    } else {
      window.location.reload()
    }
  }
}

import {
  Controller
} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["title"];
  ;

  connect() {
  }

  change() {
    var randomColor = '#' + Math.floor(Math.random() * 16777215).toString(16);
    this.titleTarget.style.color = randomColor;
  }
}
import { Controller } from "@hotwired/stimulus"
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="organization-notes"
export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    console.log('its me ya boy!')
  }

  openEdit(e) {
    e.preventDefault() 
    
    let url = window.location.origin + e.target.dataset.urlValue
    this.fetchInfo(url)
  }

  fetchInfo(link) {
    fetch(link, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}

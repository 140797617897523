import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="other-pronoun"
export default class extends Controller {
  static targets = ['other'];
  static values = {
    other: String
  }

  change(e){
    if (!this.hasOtherTarget && e.target.value == 'Other') {
      console.log ('rendering other pronoun')
      this.renderOtherPronoun(e.target)
    } else if (this.hasOtherTarget && e.target.value != 'Other') {
      this.otherTarget.remove()
    }
  }

  renderOtherPronoun(selectDiv){
    let div = document.createElement('div')
    div.setAttribute('data-other-pronoun-target', 'other')
    let pronounOther = Object.assign(div, {
      className: "form-group string optional client_pronouns_other",
      innerHTML: `
        <label class="control-label string optional" for="client_pronouns_other">Pronoun Other</label>
        <input class="form-control string optional" type="text" value="${selectDiv.dataset['otherValue']}" name="client[pronouns_other]" id="client_pronouns_other">
      `
    })
    selectDiv.parentElement.after(pronounOther)
  }
}

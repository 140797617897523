import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="bookmark"
export default class extends Controller {
  static targets = ['bookmarks', 'name', 'bookmark']

  connect() {
    this.baseUrl = window.location.origin
    this.currentUrl = window.location.href
    console.log('Bookmark Connected !')
  }

  remove(e) {
    let el = e.target.parentNode
    let data = {
      bookmark: {
        name: el.children[0].innerText,
        url: el.children[0].href
      } 
    }
    this._sendDel(el, data)
  }

  add() {
    let name = this.nameTarget.value
    this._sendPost({ bookmark: {name, url: window.location.href }})
    this.nameTarget.value = ''
  }

  _replace() {
    let url = this.currentUrl
    this.bookmarkTargets.forEach(element => {
      if (element.children[0].href === url ) {
        element.remove()
      }
    });
  }

  _createItem(item) {
    let element = document.createElement('li')
    element.setAttribute('data-bookmark-target', 'bookmark')

    return Object.assign(
      element,
      {
        className: 'bookmark-item',
        innerHTML: `<a class="block hover:bg-gray-50 bookmark-link" href="${item.url}">
                      ${item.name}
                    </a>
                    <span data-action="click->bookmark#remove"> x </span>`
      }
    )
  }

  _addToList(item) {
    let bookmarks = this.bookmarksTarget
    bookmarks.prepend(item)
  }

  _sendPost(data) {
    fetch(`${this.baseUrl}/my_stuff/bookmark`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(data => {
        console.log('Success', data)
        this._replace()
        let item = this._createItem(data)
        this._addToList(item)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  _sendDel(target, data) {
    fetch(`${this.baseUrl}/my_stuff/delete`,{
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    })
    .then(response => response.json())
    .then(dat => {
      target.remove()
      console.log('removed')
    })
    .catch(error => console.log(error))
  }
}

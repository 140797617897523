import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="ronnys-corner"
export default class extends Controller {
  connect(){
    console.log('Welcome to Ronny\'s Corner')
    // let audio = new Audio('https://www.myinstants.com/en/instant/hello-there-obi-wan-39670/?utm_source=copy&utm_medium=share')
    // audio.play()
  }

  helloThere(){
    // console.log('clicked')
    // let audio = new Audio(sound)
    // // audio.crossOrigin = 'anonymous'
    // audio.load()
    // audio.play()
    //   .then(() => console.log('playing'))
    //   .catch(e => console.error(e))
  }
}

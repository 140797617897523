import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="settings"
export default class extends Controller {
  
  initialize() {
    this.editing = false
  }

  edit(e) {
    let name = e.target.parentElement.previousSibling

    if (this.editing) {
      e.target.className = 'fa fa-pencil'

      this._saveOption(name)
    } else {
      e.target.className = 'fa fa-hdd-o'
      let select = document.createElement('select')
      
      this._getOptions()
        .then(data => {
          let selected;
          for(let i = 0; i < data.length; i++) {
            selected = name.data.trim() === data[i].fullName ? 'selected' : ''
            select.innerHTML += `<option value="${data[i].id}" ${selected}>${data[i].fullName}</option>`
          }
          name.replaceWith(select)
        })
    }

    this.editing = !this.editing
  }


  _getOptions() {
    return fetch(`${window.location.origin}/ronnys_corner/users`,{
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
  }

  _saveOption(name) {

    fetch(`${window.location.origin}/ronnys_corner/default_wl_case_manager`,{
      method: 'PATCH',
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({id: name.value})
    })
    .then(response => response.json())
    .then(data => {
      name.replaceWith(data.name)
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

// Connects to data-controller="gmaps"
export default class extends Controller {
  connect() {
    const loader = new Loader({
      apiKey: "AIzaSyA7r9yCI74wD3cP4RlYmPEL8Bk7fOZAdPo",
      version: "weekly",
    });
    
    loader.load().then(async () => {
      const { Map, InfoWindow } = await google.maps.importLibrary("maps");
      const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
    
      const map = new Map(document.getElementById("map"), {
        center: { lat: -34.397, lng: 150.644 },
        zoom: 8,
        mapId: 'gmap'
      });

      const infoWindow = new InfoWindow({content: ''})
      let bounds = new google.maps.LatLngBounds();
      const marker_params = await JSON.parse(this.element.dataset.markers)
      const markers = marker_params.map(marker_data => {
        const position = new google.maps.LatLng(marker_data.lat, marker_data.lng);
        bounds.extend(position);
        const marker = new AdvancedMarkerElement({
          map,
          position: marker_data,
        })
        marker.addListener('click', function() {
          if (infoWindow.getMap()) {
              infoWindow.close();
          } else {
              infoWindow.setContent(marker_data.infowindow)
              infoWindow.open(map, marker);
          }
        });
        if (marker_data.picture) {
          marker.content = $(`<img src='${marker_data.picture.url}'/>`)[0]
        }
        return marker
      })
      
      const cluster = new MarkerClusterer({map: map, markers: markers});
      map.fitBounds(bounds);
    });
  }
}

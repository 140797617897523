import { Application } from "@hotwired/stimulus";
// import * as bootstrap from 'bootstrap'

const application = Application.start();

// Configure Stimulus development experience
application.warnings = true;
application.debug = false;
window.Stimulus = application;
// window.bootstrap = bootstrap

// import Dropdown from 'stimulus-dropdown'
// application.register('dropdown', Dropdown)

export { application };

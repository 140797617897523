export default function waitForElm(selector) {
  return new Promise(resolve => {
    if ($(selector).length && $(selector).css('display') != 'none') {
      return resolve($(selector));
    }

    const observer = new MutationObserver(mutations => {
      if ($(selector).length && $(selector).css('display') != 'none') {
        observer.disconnect();
        resolve($(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}
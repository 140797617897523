import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notes-filter"
export default class extends Controller {
  static targets = ['setFilters', 'note', 'table']
  
  initialize() {
    this.settings = {
      author: [],
      who: [],
      what: [],
      how: []
    }

    this.notableParams = {
      notable_id: this.element.dataset.notableId,
      notable_type: this.element.dataset.notableType
    }

    this.original = this.noteTargets
  }

  filterBy(e){
    e.preventDefault();

    let setting = Object.keys(e.params)[0]
    this.settings[setting] = [...this.settings[setting], e.params[setting]]

    this.showOptions(e)
  }

  removeFilter(e){
    e.preventDefault()
    const value = e.target.dataset.value
    const filterType = e.target.dataset.filterType

    if (filterType === 'author') {
      this.settings[filterType] = this.settings[filterType].filter(item => {
        return !(item[0] === value)
      })
    } else {
      this.settings[filterType] = this.settings[filterType].filter(item => {
        return !(item === value)
      })
    }
    
    this.showOptions(e)
  }

  showOptions(e){
    this.setFiltersTarget.innerHTML = ''

    for (const key in this.settings) {
      if ( !this.settings[key].length ) { continue; }
      
      this.settings[key].forEach((item) => {
        this.renderFilter(item, key)
      })
    }

    this.getResults(e)
  }

  renderFilter(filter, type){
    this.setFiltersTarget.innerHTML += `
              <div class="filter-option">
                ${type === 'author' ? filter[1] : filter}
                <a href='#' data-value='${type === 'author' ? filter[0] : filter}' data-filter-type='${type}' data-action='notes-filter#removeFilter' class='remove-filter-btn' data-type='filter' >
                  x
                </a>
              </div>
    `
  }

  async getResults(e){
    const urlParams = () => {
      let settings = {...this.settings};
      settings['author'] = this.settings['author'].map(i => {
        return i[0]
      });
      if (e.currentTarget.dataset.type === 'filter') {
        return new URLSearchParams({...settings, ...this.notableParams});
      } else {
        return new URLSearchParams(settings);
      }
    }

    fetch('/frames/org_notes_list?' + urlParams(), {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html));
  }
}

import { Controller } from "@hotwired/stimulus"
import { StreamActions } from "@hotwired/turbo"

// Connects to data-controller="affiliate-placement-modal"
export default class extends Controller {
  connect() {
    this.constructModal()
    this.applyModalParams()

    StreamActions.affiliatePlacementCreate = this.affiliatePlacementCreate
  }

  disconnect() {
    $(`#${this.element.id}`).modal().hide()
  }

  applyModalParams() {
    $('.open-ap-modal').on('click', (e) => {
      const val = e.currentTarget.dataset.reload
      $('#reload_reload').val(val)
      $('#reload_reload').toggle('change')
    })
  }

  constructModal() {
    $("a[href$='#assign_clinician'").click(function () {
      var tina = $('#new_affiliate_placement_modal').data('tina')

      $('button.assign-affiliate-submit-button').click(function (e) {
        if (tina) {
          if (!confirm(
              "Tina Kinard the parent coach is assigned to this case. Are you sure that you want to assign an affiliate?"
              )) {
            e.preventDefault();
          }
        }
      });
      var $contact_affiliate = $('#contact_affiliate');
      var $contact_modality = $('#contact_modality_div');
      $contact_affiliate.change(function () {
        if ($contact_affiliate.val() == 'true') {
          $contact_modality.show();
        }
        if ($contact_affiliate.val() != 'true') {
          $contact_modality.hide();
        }
      })

      $('button.approve-affiliate-submit-button').click(function (e) {
        if (tina) {
          if (!confirm(
              "Tina Kinard the parent coach is assigned to this case. Are you sure that you want to assign an affiliate?"
              )) {
            e.preventDefault();
          }
        }
      });
    });
  }

  affiliatePlacementCreate() {
    const errors = this.getAttribute('errors')

    $('#new_affiliate_placement_modal').modal('hide');
    $('#all_counseling_interventions').modal('hide')

    if ($('#edit_intake').hasClass('in')) {
      if (errors) {
        $.createIntakeNotification(errors, 'danger')
      } else {
        $.createIntakeNotification('Affiliate Placement Created.')
      }
    } else {
      window.location.reload()
    }
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="progress-note"
export default class extends Controller {
  connect() {
    $(".date-picker").datepicker();

    var table = $('#progress_notes').DataTable( {
      buttons: ['print', 'copy', 'csv', 'pdf'],
      "aaSorting": [[0,'desc']]
    });
    table.buttons().container()
    .appendTo( $('.col-sm-6:eq(0)', table.table().container() ) );

    $('.note_to_expand').readmore({
      collapsedHeight: 100,
      speed: 50,
      lessLink: '<a href="#">Read less</a>'
    });
  };
};
import { Controller } from "@hotwired/stimulus"
import waitForElm from "../helpers/waitForElement"

// Connects to data-controller="embeded-link-name"
export default class extends Controller {
  static values = {
    embeded: Object
  }

  open(e) {
    let client = e.params.payload.client
    let link = e.params.payload.link
    let intakeId = e.params.payload.intakeId
    
    if (!client && !link && !intakeId) {
      return
    }

    SavvyCal('open', { // opening savvycal portal 
      link: link,
      metadata: {
        client_id: client.id,
        intake_id: intakeId
      },
      email: client.email,
      displayName: client.displayName
    });

    waitForElm('#sc-embed > div > div').then((element) => {
      element.css('z-index', 10000000)
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="event-form"
export default class extends Controller {

  initialize() {
    this.state = {}
  }

  connect() {
    $('#event_event_type')[0].setAttribute('data-action', 'change->event-form#eventType')
    $('#event_date')[0].setAttribute('data-action', 'change->event-form#setTime')
    $('#event_series')[0].setAttribute('data-action', 'change->event-form#series')
    $('#event_organization_id')[0].setAttribute('data-action', 'change->event-form#organization')
    $('#event_location_id')[0].setAttribute('data-action', 'change->event-form#virtual')
    $('#event_event_manager_id')[0].setAttribute('data-action', 'change->event-form#showFormLink')

    this._setJsEvents('#event_event_manager_id')
    this._setJsEvents('#event_location_id')
    this._setJsEvents('#event_event_type')
    this._setJsEvents('#event_series')
    this._setJsEvents('#event_organization_id')
    this._populateForm()
  }

  showFormLink(){
    if ($('#event_event_manager_id').val() === '4246' && $('#event_event_type').val() === 'Benefits Fair') {
      $('#materials_form_link').show()
    } else {
      $('#materials_form_link').hide() 
    }
  }

  virtual(e) {
    if ($('#event_location_id').find(':selected').text()) {
      $('.event_equipment_needs').hide()
      $('.event_location_details').hide()
      $('.event_record_meeting').show()
      $('.event_virtual_platform_host').show()
      $('.event_online_meeting_link').show()
    } else {
      $('.event_location_details').show()
      $('.event_equipment_needs').show()
      $('.event_record_meeting').hide()
      $('.event_virtual_platform_host').hide()
      $('.event_online_meeting_link').hide()
    }
  }

  organization(e) {
    this._fetchContacts(e)
    this._enableOrgInputs(e.target.value !== '')
  }

  eventType(e) {
    if (e.target.value === 'Wellness' ) {
      $('#categoryInput').show()
    } else {
      $('#categoryInput').hide()
    }

    this.showFormLink()
  }

  series(e) {
    this._enableSeriesInputs(e.target.value)
  }

  setTime(e) {
    let time = e.target.value.split('T')[1]
    $('#event_time')[0].value = time
  }

  // reloadFrame(e) {
  //   let frames = e.params.frames.ids
  //   console.log(frames)
  //   if (frames.length !== 0) {
  //     frames.forEach(f => {
  //       if ($(`#${f}`).length === 0) {
  //         $(`#${f}`)[0].reload()
  //       }
  //     })
  //   }
  // }

  _populateForm() {
    if ($('#event_organization_id').val() !== ''){
      this._enableOrgInputs(true)
    }
    this._enableSeriesInputs($('#event_series').val())
    this.showFormLink()
  }

  _enableSeriesInputs(val) {
    console.log(val)
    switch (val) {
      case 'DEI':
        // $('#event_event_type')[0].disabled = true
        // $('#event_category')[0].disabled = true
        $('#categoryInput').hide()
        $('#eventType').hide()

        break
      case 'Monthly Wellness':
        // $('#event_category')[0].disabled = false
        $('#eventType').hide()
        $('#categoryInput').show()

        break
      case 'Overviews':
        // $('#event_event_type')[0].disabled = true
        // $('#event_category')[0].disabled = true
        $('#categoryInput').hide()
        $('#eventType').hide()

        break
        default:
        // $('#event_category')[0].disabled = true
        // $('#event_event_type')[0].disabled = false
        $('#eventType').show()
        $('#categoryInput').hide()

    }
  }

  _enableOrgInputs(bool) {
    // refactor
    if ( bool ) {
      $('#event_location_id')[0].disabled = false
      $('#event_contact_id')[0].disabled = false
      $('#event_site_contact_id')[0].disabled = false
    } else {
      $('#event_location_id')[0].disabled = true
      $('#event_contact_id')[0].disabled = true
      $('#event_site_contact_id')[0].disabled = true
    }
  }

  _fetchContacts(e) {
    this.state.orgId = e.target.value
    fetch(`/events/populate_org_contacts/${this.state.orgId}`)
      .then((resp) => resp.json())
      .then(json => {
        this._updateState(json)
      })
  }

  _setEventContact() {
    this.state.contacts.forEach( c => {
      // refactor if possible
      $('#event_contact_id').append(new Option(c.name, c.id, false, false)).trigger('change');
      $('#event_site_contact_id').append(new Option(c.name, c.id, false, false)).trigger('change');
    })

    this.state.locations.forEach( l => {
      $('#event_location_id').append(new Option(l.name, l.id, false, false)).trigger('change');
    })
  }

  _updateState(json) {
    this.state = json

    console.log(this.state)
    this._setEventContact()
  }

  // https://stackoverflow.com/questions/70280216/how-do-i-listen-to-select2-events-in-a-stimulus-controller
  // allows to listen to the change event from select2
  _setJsEvents(selector) {
    $(selector).on('select2:select', function () {
      let event = new Event('change', { bubbles: true }) // fire a native event
      this.dispatchEvent(event);
    });
  }
}

import { Controller } from "@hotwired/stimulus";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
// import Turbolinks from "turbolinks";

export default class extends Controller {
  static targets = ["calendar"];

  initialize() {
    this.affiliate = Number(this.element.dataset.affiliate);
  }

  connect() {
    let _this = this;
    if (!isNaN(this.affiliate)) {
      var selected = this.affiliate;
      let calendar = new Calendar(this.calendarTarget, {
        events: {
          url: "/affiliate_events.json",
          method: "GET",
          extraParams: {
            clinician_id: this.affiliate,
          },
        },
        initialView: "timeGridWeek",
        slotMinTime: "07:00:00",
        slotMaxTime: "22:00:00",
        height: "auto",
        editable: true,
        navLinks: true,
        headerToolbar: { center: "dayGridMonth,timeGridWeek,timeGridDay" },
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        dateClick: function (info) {
          let dateInfo = {
            "affiliate_event[start_time]": info.dateStr,
            "affiliate_event[all_day]": info.allDay,
            "affiliate_event[affiliate_ids]": selected,
          };
          $.ajax({
            type: "POST",
            url: "/affiliate_events.json",
            data: new URLSearchParams(dateInfo).toString(),
            success: function (response) {
              console.log(info)
              calendar.addEvent({
                url:  "/affiliate_events/" + response.id + "/edit",
                showUrl: "/affiliate_events/" + response.id + "/edit",
                id: response.id,
                title: "Available",
                start: info.dateStr,
                allDay: info.allDay,
              });
            },
          });
        },
        navLinkDayClick: function (date, jsEvent) {
          _this.modalTarget.style.display = "block";
          _this.start_timeTarget.value = date;
          _this.end_timeTarget.value = date;
        },
        eventDrop: function (info) {
          let data = _this.data(info);
            $.ajax({
              type: "PUT",
              url: "/affiliate_events/" +  info.event.id,
              data: new URLSearchParams(data).toString(),
            });
        },
        eventResize: function (info) {
          let data = _this.data(info);
          $.ajax({
            type: "PUT",
            url: "/affiliate_events/" +  info.event.id,
            data: new URLSearchParams(data).toString(),
          });
        },
      });
      calendar.render();
    } else {
      let calendar = new Calendar(this.calendarTarget, {
        events: {
          url: "/affiliate_events.json",
          method: "GET",
        },
        initialView: "timeGridWeek",
        slotMinTime: "07:00:00",
        slotMaxTime: "22:00:00",
        height: "auto",
        editable: true,
        navLinks: true,
        headerToolbar: { center: "dayGridMonth,timeGridWeek,timeGridDay" },
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        dateClick: function (info) {
          alert("Please select a clinician to add an event");
        },
        navLinkDayClick: function (date, jsEvent) {
          _this.modalTarget.style.display = "block";
          _this.start_timeTarget.value = date;
          _this.end_timeTarget.value = date;
        },
        eventDrop: function (info) {
          let data = _this.data(info);
          $.ajax({
            type: "PUT",
            url: "/affiliate_events/" +  info.event.id,
            data: new URLSearchParams(data).toString(),
          });
        },
        eventResize: function (info) {
          let data = _this.data(info);
          $.ajax({
            type: "PUT",
            url: "/affiliate_events/" +  info.event.id,
            data: new URLSearchParams(data).toString(),
          });
        },
      });
      calendar.render();
    }
  }

  set_clinician() {
    let _this = this;
    var select = document.getElementById("clinician_select");
    var selected = [...select.selectedOptions].map((option) => option.value);
    let calendar = new Calendar(this.calendarTarget, {
      events: {
        url: "/affiliate_events.json",
        method: "GET",
        extraParams: {
          clinician_id: [selected],
        },
      },
      initialView: "timeGridWeek",
      slotMinTime: "07:00:00",
      slotMaxTime: "22:00:00",
      height: "auto",
      editable: true,
      navLinks: true,
      headerToolbar: { center: "dayGridMonth,timeGridWeek,timeGridDay" },
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      dateClick: function (info) {
        let dateInfo = {
          "affiliate_event[start_time]": info.dateStr,
          "affiliate_event[all_day]": info.allDay,
          "affiliate_event[affiliate_ids]": selected,
        };
        if (selected.length > 1) {
          alert("Please select only one clinician to add an event");
        } else {
          $.ajax({
            type: "POST",
            url: "/affiliate_events",
            data: new URLSearchParams(dateInfo).toString(),
            success: function (response) {
              console.log(info)
              calendar.addEvent({
                url: "/affiliate_events/" + response.id + "/edit",
                showUrl: "/affiliate_events/" + response.id + "/edit",
                id: response.id,
                title: "Available",
                start: info.dateStr,
                allDay: info.allDay,
              });
            },
          });
        }
      },
      navLinkDayClick: function (date, jsEvent) {
        _this.modalTarget.style.display = "block";
        _this.start_timeTarget.value = date;
        _this.end_timeTarget.value = date;
      },
      eventDrop: function (info) {
        let data = _this.data(info);
        $.ajax({
          type: "PUT",
          url: "/affiliate_events/" +  info.event.id,
          data: new URLSearchParams(data).toString(),
        });
      },
      eventResize: function (info) {
        let data = _this.data(info);
        $.ajax({
          type: "PUT",
          url: "/affiliate_events/" +  info.event.id,
          data: new URLSearchParams(data).toString(),
        });
      },
    });
    calendar.render();
  }

  data(info) {
    return {
      "affiliate_event[start_time]": info.event.start,
      "affiliate_event[end_time]": info.event.end,
    };
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="manager-consults"
export default class extends Controller {
  changeOrganization(e) {
    this.url = "/manager_consults/consults_for_organization?" + new URLSearchParams({organization_id: $('#manager_consult_organization_id').val()})
    fetch(this.url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => Turbo.renderStreamMessage(html))
  }
}

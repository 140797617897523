import { Controller } from "@hotwired/stimulus"
import { Turbo } from '@hotwired/turbo-rails'

// Connects to data-controller="turbo-edit"
export default class extends Controller {
  static values = {
    url: String
  }

  loadInfo(e) {
    let url = window.location.origin + e.params.url
    this.fetchInfo(url)
  }

  fetchInfo(link) {
    fetch(link, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html'
      }
    })
    .then(r => r.text())
    .then(html => {
      Turbo.renderStreamMessage(html)
    })
  }
}
